import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'hb-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  styles: [],
  standalone: true,
  imports: [MatIconModule]
})
export class MapComponent {
  srcUrl!: string;

  constructor(
    private router: Router,
    private location: Location,
    private activeRoute: ActivatedRoute,
    public sanitizer: DomSanitizer
  ) {
    const lat = this.activeRoute.snapshot.paramMap.get('lat');
    const long = this.activeRoute.snapshot.paramMap.get('long');

    this.srcUrl = `https://www.google.com/maps/place/${lat},${long}&embedded=true`;
    // this.srcUrl = `https://maps.google.com/maps?z=12&t=m&q=loc:${lat}+${long}`;
  }

  navigateToEventsPage() {
    this.location.back();
  }
}
