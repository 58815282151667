<div class="map-container">
  <div class="top-bar flex-row">
    <div class="flex-1">
      <mat-icon class="arrow-back cursor-pointer" (click)="navigateToEventsPage()">arrow_back</mat-icon>
    </div>
    <span class="page-label justify-center flex-1" i18n>Map</span>
    <div class="flex-1"></div>
  </div>

  <div class="location-container">
    <iframe
      [src]="sanitizer.bypassSecurityTrustResourceUrl(srcUrl)"
      width="100%"
      height="315"
      frameborder="0"
      allowfullscreen
      webkitallowfullscreen
      mozallowfullscreen
    >
    </iframe>
  </div>
</div>
